<template>
  <div class="mt-4 mx-3 row white-box px-3 py-4">
    <div class="white-box-top"></div>
    <div class="col-md-4 col-lg-2">
      <nav class="left-nav">
        <div class="items">
          <button
            class="btn w-100 text-start"
            :class="activeType === null ? 'btn-primary' : 'btn-simple'"
            v-on:click="activeType = null"
          >
            {{ $t("companies.viewAll") }}
          </button>
          <button
            class="btn w-100 text-start"
            :class="activeType === id ? 'btn-primary' : 'btn-simple'"
            v-for="(name, id) in types"
            v-on:click="activeType = id"
            :key="id"
          >
            {{ name }}
          </button>
        </div>
      </nav>
    </div>
    <div class="col-md-8 col-lg-10">
      <div class="row mb-4">
        <div class="col">
          <h2>
{{ $t("integrations.integrations") }}
</h2>
        </div>
        <div class="col-auto">
          <button class="btn btn-type-2 btn-small" v-on:click="showAddNewModal">
            <span>
{{ $t("integrations.addNew2") }}
</span>
          </button>
        </div>
      </div>
      <div class="row">
        <div
          class="col-md-6 col-lg-3 mb-4 integration-box"
          v-for="integration in integrations.filter((e) => (e.app_type ?? 0) == activeType || activeType === null)"
          :key="integration.id"
        >
          <div class="text-center my-2">
            <img
              class="rounded icon"
              :src="
                storageUrl +
                '/' +
                integration.class_name.split('\\')[0] +
                '/' +
                integration.icon
              "
              v-if="integration.icon"
              :alt="integration.name"
            />
            <BaseIcon v-else name="puzzle" color="#10355a" />
          </div>
          <h3>{{ integration.name }}</h3>
          <span class="text-gray regular-14">{{
            types[integration.app_type ? integration.app_type : 0]
          }}</span>
          <p class="regular-14">{{ integration.description }}</p>
          <div class="row my-3">
            <div class="col">
              <button
                class="btn btn-small btn-primary"
                v-if="integration.enabled"
                v-on:click="disableApp(integration)"
              >
                {{ $t("integrations.enabled") }}
              </button>
              <button
                class="btn btn-small btn-outline-primary"
                v-on:click="enableApp(integration)"
                v-else
              >
                {{ $t("integrations.enable") }}
              </button>
            </div>
            <div class="col text-end">
              <button class="btn btn-small btn-simple" v-on:click="openSettings(integration);">
{{ $t("integrations.options") }}
</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <AddNewIntegration ref="newIntegration" />
  <IntegrationSettingsModal
    ref="integrationSettings"
    v-model="selectedIntegration"
  />
</template>

<script>
import { defineAsyncComponent } from "vue";
import AddNewIntegration from "../modals/AddNewIntegration";
import http from "../../modules/http";
import IntegrationSettingsModal from "@/components/modals/IntegrationSettingsModal";
const BaseIcon = defineAsyncComponent(() => import("../icons/BaseIcon.vue"));
export default {
  name: "integrations",
  components: { IntegrationSettingsModal, AddNewIntegration, BaseIcon },
  data() {
    return {
      selectedIntegration: null,
      activeType: null,
      types: {
        0: "Basic",
        1: "Invoice",
        2: "Payment",
        3: "Shipment",
        4: "Feed",
        5: "Bank connect",
        99: "Front end",
      },
      integrations: [],
    };
  },
  computed: {
    storageUrl() {
      return process.env.VUE_APP_FILES;
    },
  },
  mounted() {
    http.fetch("/integrations").then((data) => {
      this.integrations = data;
    });
  },
  methods: {
    showAddNewModal() {
      this.$refs.newIntegration.showModal();
    },
    disableApp(integration) {
      http.fetch("/integrations/disable", { id: integration.id }).then(() => {
        integration.enabled = false;
      });
    },
    enableApp(integration) {
      http.fetch("/integrations/enable", { id: integration.id }).then(() => {
        integration.enabled = true;
      });
    },
    openSettings(integration) {
      this.selectedIntegration = integration;
      this.$refs.integrationSettings.showModal();
    },
  },
};
</script>
